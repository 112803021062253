import Input from './common/Input'
import Button from './common/Button'
import { useState } from 'react'
import { useResponse, validateEmail } from './utils'
import Preloader from './common/Preloader'
import Responser from './common/Responser'

const Form = ({className = ''}) => {
  const [email, setEmail] = useState('')
  const {response, toggleResponse} = useResponse()
  const [pending, setPending] = useState(false)

  const submit = () => {
    validateEmail(email, () => {
      setPending(true)
      fetch(`https://neon-labs.org/api/subscribers/create`, {
        method: 'POST',
        body: JSON.stringify({email}),
        headers: {
          'Content-Type': 'application/json'
        },
      }).then((resp) => {
        if (resp.ok) resp.json().then((r) => toggleResponse(r.message))
      }).catch(error => {
        if (error.response && error.response.status) toggleResponse(error.response.status)
      }).finally(() => {
        setPending(false)
        setEmail('')
      })
    }, () => {
      toggleResponse('Please enter your email address!')
  })
  }

  return <div className={`${className} bg-white rounded-lg flex items-start px-16 py-10 mb-10 sm:flex-col sm:px-8 sm:py-6`}>
    <div className={'flex flex-col pr-10 w-5/12 sm:w-full sm:pr-0 sm:mb-8'}>
      <div className={'text-3xl font-bold mb-6'} style={{maxWidth: '260px'}}>Sign up for the Project updates</div>
      <div className={'text-lg text-dark-gray'}>We’ll update you on our project status & let you know when we’ve launch on Mainnet.</div>
    </div>
    <div className={'w-7/12 pl-10 sm:w-full sm:pl-0'}>
      <div className='w-full flex mb-6 md:flex-col xs:flex-col'>
        <Input className={'mr-6 xs:mr-0 xs:mb-6 md:mr-0 md:mb-6'} placeholder={'Your Email'} value={email} onChange={(value) => setEmail(value)}/>
        <Button onClick={submit}>Send me updates</Button>
      </div>
      <div className='w-full flex'>
        {pending ? <Preloader /> : null}
        <Responser className='subscriber__response' 
          successLocale='Congrats! You are subscribed!' status={response}/>
      </div>
    </div>
  </div>
}

export default Form