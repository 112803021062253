import React from "react"

const Button = ({className = '',
children = null,
to = '',
disabled = false,
onClick = () => {}}) => {
    const classNames = `${className} button 
        ${disabled ? 'button--disabled' : ''}`
    return <React.Fragment>
        {to.length ? <a href={to} target='_blank' 
            rel='noopener noreferrer' 
            className={classNames}>
                {children}
            </a> : <div className={classNames} onClick={onClick}>{children}</div>}
    </React.Fragment>
}
export default Button