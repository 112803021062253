import './App.css';
import {ReactComponent as Logo} from './assets/logo_head.svg'
import {ReactComponent as Banner} from './assets/banner.svg'
import {ReactComponent as Star} from './assets/star.svg'
import pictureUrl from './assets/team.png'
import {ReactComponent as Star1} from './assets/star_1.svg'
import {ReactComponent as Star2} from './assets/star_2.svg'
import {ReactComponent as Star3} from './assets/star_3.svg'
import Footer from './Footer'

function App() {
  return <div className="w-full bg-dark min-h-screen">
     <div className='mx-auto px-4 flex flex-col items-center pt-20 text-center xs:text-left' style={{maxWidth: '860px'}}>
       <Logo className='mb-12'/>
       <h1 className='text-6xl font-bold mb-6 leading-none'>We are hiring <br/>in Neon Labs team</h1>
       <p className='text-2xl mb-8'>Join Neon as a member of a fast-growing startup, <br/>shape the future of blockchains and web3.0 ecosystem. </p>
       <Banner className='my-20'/>
       <h2 className='text-6xl font-bold mb-6 leading-none'>Why Neon?</h2>
       <p className='text-4xl mb-16 leading-snug text-center'>By working for Neon you'll be creating <br/>sophisticated and in high-demand products that impact the evolution of the blockchain. </p>
       <div style={{maxWidth: '520px'}} className='flex flex-col mb-20'>
        <p className='text-xl text-left mb-4'>
          <span className='text-red'>A good example is the very first EVM on Solana</span>
          <br/>that enables dApp developers to benefit from  both blockchains.</p>
        <p className='text-xl text-left'>In Neon Labs each has the freedom to express own ideas, get feedback and make those ideas a reality  with the help of high-caliber teammates.</p>
       </div>
      </div>
      <div className='mx-auto px-4 flex flex-col items-center pt-20 text-center' style={{maxWidth: '1020px'}}>
        <div className=' w-full flex items-center sm:flex-col sm:items-center'>
          <div className='pr-6 sm:pr-0 sm:mb-12'>
            <img src={pictureUrl} alt='team' style={{maxWidth: '540px'}}/>
          </div>
          <div className='text-left pl-10 sm:pl-0 sm:mb-12' style={{maxWidth: '420px'}}>
            <p style={{
              'background': '-webkit-linear-gradient(#FE6475, #C121DE)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }} className='text-2xl'>A career at Neon is not <br/>simply a job, by choosing <br/>to work at Neon you are choosing to shape the future of the blockchain.</p>
          </div>
        </div>
        <div className='mb-10'>
          <Star/>
        </div>
      </div>
      <div className='mx-auto px-4 flex flex-col items-center pt-20 text-center' style={{maxWidth: '1020px'}}>
        <h3 className='text-4xl font-bold mb-8 leading-none'>Open Roles</h3>
        <div className='w-full flex mb-24 sm:flex-col'>
          <div className='w-1/3 sm:w-full sm:flex sm:justify-center sm:mb-8'>
            <div className='bg-dark-grey mr-2 rounded-lg px-10 py-8 relative sm:w-5/6'>
              <div className='absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center z-10'>
                <Star1/>
              </div>
              <div className='flex flex-col leading-none text-left relative z-20'>
                <div className='text-xl'>Senior</div>
                <div className='text-2xl font-medium'>Rust Developer</div>
                <span className='mt-10'>
                  <a className='text-yellow' href="https://jobs.solana.com/jobs?o=37179" target="_blank" rel="noopener noreferrer">View on Solana Jobs</a>
                </span>
              </div>
            </div>
          </div>
          <div className='w-1/3 sm:w-full sm:flex sm:justify-center sm:mb-8'>
            <div className='bg-dark-grey mr-2 rounded-lg px-10 py-8 relative sm:w-5/6'>
              <div className='absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center z-10'>
                <Star2/>
              </div>
              <div className='flex flex-col leading-none text-left relative z-20'>
                <div className='text-xl'>Senior</div>
                <div className='text-2xl font-medium'>QA Engineer</div>
                <span className='mt-10'>
                  <a className='text-yellow' href="https://jobs.solana.com/jobs?o=37179" target="_blank" rel="noopener noreferrer">View on Solana Jobs</a>
                </span>
              </div>
            </div>
          </div>
          <div className='w-1/3 sm:w-full sm:flex sm:justify-center sm:mb-8'>
            <div className='bg-dark-grey mr-2 rounded-lg px-10 py-8 relative sm:w-5/6'>
              <div className='absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center z-10'>
                <Star3/>
              </div>
              <div className='flex flex-col leading-none text-left relative z-20'>
                <div className='text-xl'>Senior</div>
                <div className='text-2xl font-medium'>DevOps Engineer</div>
                <span className='mt-10'>
                  <a className='text-yellow' href="https://jobs.solana.com/jobs?o=37179" target="_blank" rel="noopener noreferrer">View on Solana Jobs</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
}

export default App;