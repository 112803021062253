import Form from './Form'
import {ReactComponent as Twitter} from './assets/twitter.svg'
import {ReactComponent as Discord} from './assets/discord.svg'
import {ReactComponent as Github} from './assets/github.svg'
import {ReactComponent as Youtube} from './assets/youtube.svg'

const Footer = () => {
  return <div className='w-full bg-whity text-dark p-6'>
    <Form className='w-full'/>
    <div style={{maxWidth: '1280px'}} className='w-full flex justify-between mx-auto sm:flex-col sm:justify-center sm:items-center'>
      <div className='flex items-center sm:justify-center w-1/4 sm:w-full sm:mb-10'>
        <a href='https://twitter.com/neonlabsorg' rel='noopener noreferrer' target='_blank' className='mx-4'>
          <Twitter />
        </a>
        <a href='https://discord.gg/ApZRBDqYcN' rel='noopener noreferrer' target='_blank' className='mx-4'>
          <Discord />
        </a>
        <a href='https://github.com/neonlabsorg' rel='noopener noreferrer' target='_blank' className='mx-4'>
          <Github />
        </a>
        <a href='https://www.youtube.com/channel/UCAL2uFkKkfBgz3mXFN3UDTQ' rel='noopener noreferrer' target='_blank' className='mx-4'>
          <Youtube />
        </a>
      </div>
      <div className='flex justify-between sm:flex-col sm:items-center w-7/12 sm:w-full md:w-4/6 '>
        <span className='mx-2 sm:mb-8'>All rights reserved © 2022</span>
        <a className='mx-2 sm:mb-4' href="https://neon-labs.org/terms_of_use_v3.pdf" rel='noopener noreferrer' target='_blank'>Terms of Use</a>
        <a className='mx-2 sm:mb-4' href="https://neon-labs.org/Cookies_policy_v3.pdf" rel='noopener noreferrer' target='_blank'>Cookies Policy</a>
        <a className='mx-2 sm:mb-4' href="https://neon-labs.org/disclaimer_v2.pdf" rel='noopener noreferrer' target='_blank'>Disclamer</a>
        <a className='mx-2 sm:mb-4' href="https://neon-labs.org/Privacy_policy_v2.pdf" rel='noopener noreferrer' target='_blank'>Privacy</a>
      </div>
    </div>
  </div>
}

export default Footer