const Responser = ({
    status = 0,
    className = '',
    successLocale = 'Succeed!',
    errorLocale = 'Sorry, something went wrong :('
}) => {
    if (status === 0) return null
    if (typeof status === 'number') {
        return <div className={`responser ${className}`}>{
            status === 200 ? successLocale : `${errorLocale} \n ${status}`
        }</div> 
    } else {
        return <div className={`responser ${className}`}>{status}</div>
    }
}

export default Responser