import { useState, useRef } from "react"

const validateEmail = (email, onSuccess, onError) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = re.test(String(email).toLowerCase())
  if (result === true) onSuccess()
  else onError()
}



const useResponse = () => {
    const responseResetTimer = useRef(null)
    const [response, setResponse] = useState(0)
    const toggleResponse = (code = 200) => {
        if (code === 0) return
        setResponse(code)
        responseResetTimer.current = setTimeout(() => {
            setResponse(0)
        }, 5000)
    }
    return {response, toggleResponse}
}

export default useResponse

export {validateEmail, useResponse}