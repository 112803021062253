import { Fragment } from "react"
const Input = ({
    className = '',
    style = {},
    value = '',
    onChange = () => {},
    placeholder = '',
    error = false
}) => {
    return <Fragment>
        <input style={style} className={`input ${className}
            ${error ? 'input--error' : ''}`} value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}/>
    </Fragment>
}

export default Input