import {ReactComponent as PreloadIcon} from '../../assets/preloader.svg'
import { useEffect, useRef, useState} from 'react'
const Preloader = ({
  overlay = false,
  hidden = false,
  className = ''
}) => {
  const loadProcess = useRef(null)
  const [loadCount, setLoadCount] = useState(0)
  useEffect(() => {
    loadProcess.current = setInterval(() => {
      setLoadCount(count => {
        if (count > 97) clearInterval(loadProcess.current)
        return count + 1
      })
    },30)
  }, [])
  return <div className={`preloader  ${className} 
      ${overlay ? 'preloader--overlayed' : ''}
      ${hidden ? 'preloader--hidden' : ''}`}>
    {overlay ? <div className='preloader__count'>{loadCount}</div> : null}
    <PreloadIcon className={'preloader__icon'}/>
  </div>
}

export default Preloader